import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Image, LinkBox, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"uber"} />
		<Helmet>
			<title>
				Über uns | Sportschule - Entwicklungs- und Sportmöglichkeiten für junge Sportler
			</title>
			<meta name={"description"} content={"Unser Team besteht aus hochqualifizierten Trainern und Spezialisten, die mit großer Leidenschaft und Verständnis für die Bedeutung des Sports für Kinder arbeiten. Unsere Trainer sind die wichtigste Ressource unserer Schule."} />
			<meta property={"og:title"} content={"Über uns | Sportschule - Entwicklungs- und Sportmöglichkeiten für junge Sportler"} />
			<meta property={"og:description"} content={"Unser Team besteht aus hochqualifizierten Trainern und Spezialisten, die mit großer Leidenschaft und Verständnis für die Bedeutung des Sports für Kinder arbeiten. Unsere Trainer sind die wichtigste Ressource unserer Schule."} />
			<meta property={"og:image"} content={"https://cubomuy.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://cubomuy.com/img/icon-man.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://cubomuy.com/img/icon-man.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://cubomuy.com/img/icon-man.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://cubomuy.com/img/icon-man.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://cubomuy.com/img/icon-man.png"} />
			<meta name={"msapplication-TileImage"} content={"https://cubomuy.com/img/icon-man.png"} />
			<meta name={"msapplication-TileColor"} content={"https://cubomuy.com/img/icon-man.png"} />
		</Helmet>
		<Components.Header />
		<Section
			text-align="center"
			background-color="--primary"
			color="--dark"
			padding="80px 0"
			sm-padding="40px 0"
			background="--color-green"
		>
			<Text
				as="h5"
				font="--lead"
				margin="10px 0 0 0"
				text-transform="uppercase"
				letter-spacing="5px"
			>
				WER WIR SIND
			</Text>
			<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0 0 0">
				Über uns
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				Seit ihrer Gründung im Jahr 1995 hat unsere Sportschule einen langen Weg zurückgelegt und ist zu einem anerkannten Zentrum für sportliche Aktivitäten und die berufliche Entwicklung junger Sportler geworden.
			</Text>
		</Section>
		<Section
			padding="0px 0 80px 0"
			lg-padding="60px 0 60px 0"
			md-padding="40px 0 40px 0"
			sm-padding="30px 0 30px 0"
			background="--color-green"
		>
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text
					font="--headline1"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Unser Team
				</Text>
				<Text font="--lead" color="--darkL2" max-width="600px">
					Unser Team besteht aus hochqualifizierten Trainern und Spezialisten, die mit großer Leidenschaft und Verständnis für die Bedeutung des Sports für Kinder arbeiten. Unsere Trainer sind die wichtigste Ressource unserer Schule.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://cubomuy.com/img/about2.jpeg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="32px 0px 8px 0px" font="--headline3" color="--dark">
							Unsere Sportinfrastruktur
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Wir verfügen über moderne Trainingsplätze, Umkleideräume und alles, was für die gesunde Entwicklung unserer Schüler notwendig ist.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://cubomuy.com/img/about1.jpeg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="32px 0px 8px 0px" font="--headline3" color="--dark">
							Partner und Sponsoren
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Wir arbeiten mit Sponsoren und Partnern zusammen, die uns bei unseren sportlichen Bemühungen und unserer Entwicklung unterstützen.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://cubomuy.com/img/about3.jpeg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="32px 0px 8px 0px" font="--headline3" color="--dark">
							Feedback von Eltern und Schülern
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Die Meinung unserer Schüler und ihrer Eltern ist uns sehr wichtig. Lesen Sie die Bewertungen und Eindrücke unserer Kunden, die ihre Erfolgsgeschichten geteilt haben.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<Box
				display="flex"
				sm-flex-direction="column"
				sm-width="100%"
				sm-text-align="center"
				justify-content="center"
				align-items="center"
			>
				<Link
					href="/kontakt"
					padding="12px 54px 12px 54px"
					color="--dark"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					sm-width="100%"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Ansprechpartner
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});